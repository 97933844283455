<template lang="pug">
.dropdown
  input#house.form-control(
    ref="inputField",
    type="text", 
    maxlength="30",
    autocomplete="off",
    placeholder="Введіть назву вулиці",
    v-model='store.inputStreetName',
    @keydown.down="onArrowDown",
    @keydown.up="onArrowUp",
    @keydown.enter="onEnter",
    @keydown.esc="escSearch",  
    )
  ul.dropdown-menu(
    :class="{ show: isDropdown }", 
    v-click-outside="clickEscSearch",    
    )
    li.dropdown-item.not-found(
      v-if="isLoading"
    ) Нічого не знайдено
    li.dropdown-item(
      v-for="(value, key) in suggestArray",
      @click = "getStreetDataFromSuggest(key)",
      :class="{ 'isActive': key === arrowCounter }",
      ) {{ value.type }} {{ value.name }}
</template>

<script>
// State engine
import { store } from '@/store.js';

// Import street data JSON
import streetData from '@/data/suggest-list.json'

// Cick Outside
import vClickOutside from "click-outside-vue3";

export default {
  name: 'StreetName',

  data(){
    return{
      store,

      suggestArray: [],

      isLoading: false,
      isDropdown: false,
      arrowCounter: -1,
    }
  },  

  directives: {
      clickOutside: vClickOutside.directive
  },

  watch:
  {
    'store.inputStreetName': function()
    {
      this.suggestArray = [];
      this.isDropdown = false;
      if(this.store.inputStreetName.length >= 2)
      {
        this.dataSearch();
      }

      if(this.store.inputStreetName == this.store.streetData.name)
      {
        this.isDropdown = false;
      }
    },
  },

  methods:
  {    
    clickEscSearch () {
      this.isDropdown = false;
      this.arrowCounter = -1;    
    },

    escSearch () {
      this.isDropdown = false;
      this.arrowCounter = -1;
      this.$refs.inputField.blur();
    },

    dataSearch()
    {
      var j = 0;
      for(let i = 0; i < streetData.length; i++)
      {
        if(streetData[i].name.toLowerCase().includes(this.store.inputStreetName.toLowerCase()))
        {
          this.suggestArray[j] = streetData[i];
          j++;
        }
      }

      if(this.suggestArray.length > 0)
      {
        this.isDropdown = true;
        this.isLoading = false;
      }
      else
      {
        this.isDropdown = true;
        this.isLoading = true;
      }
    },

    getStreetDataFromSuggest(id)
    {
      if(id >= 0)
      {
        this.store.streetData = this.suggestArray[id];
        this.store.inputStreetName = this.store.streetData.name;
        this.isDropdown = false;
      }
    },

    onArrowDown() {
      if (this.arrowCounter < this.suggestArray.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },

    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },

    onEnter() {
      this.getStreetDataFromSuggest(this.arrowCounter);
      this.arrowCounter = -1;
      this.isDropdown = false;
    }, 
  },  
}
</script>

<style lang="scss" scoped>
.dropdown-menu {
  max-height: calc(100vh - 14rem);
  min-width: 100%;
  overflow-y: scroll;
  margin-top: 0;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.175);
  .not-found {    
    padding-left: .85em;
    color: rgba($Black, .5);
  }
  .show{
  
  }
  .isActive {
    background-color: $bsBlue;
    color: #fff;
  }
}

</style>